define("discourse/plugins/retort/discourse/templates/connectors/user-preferences-notifications/retort", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.retort_enabled}}
    <div class="control-group retort">
      <label class="control-label">{{i18n "retort.retort"}}</label>
      <div class="controls">
        {{preference-checkbox
          labelKey="retort.disable_retorts"
          checked=@model.custom_fields.disable_retorts
        }}
      </div>
      <div class="controls">
        {{preference-checkbox
          labelKey="retort.hide_ignored_retorts"
          checked=@model.custom_fields.hide_ignored_retorts
        }}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "bjJF6mSl",
    "block": "[[[41,[30,0,[\"siteSettings\",\"retort_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group retort\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,3],[\"retort.retort\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"labelKey\",\"checked\"],[\"retort.disable_retorts\",[30,1,[\"custom_fields\",\"disable_retorts\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"labelKey\",\"checked\"],[\"retort.hide_ignored_retorts\",[30,1,[\"custom_fields\",\"hide_ignored_retorts\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"div\",\"label\",\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/retort/discourse/templates/connectors/user-preferences-notifications/retort.hbs",
    "isStrictMode": false
  });
});