define("discourse/plugins/retort/discourse/initializers/retort-init", ["exports", "@ember/object", "discourse/lib/get-owner", "discourse/lib/plugin-api", "discourse/plugins/retort/discourse/components/retort-button"], function (_exports, _object, _getOwner, _pluginApi, _retortButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const currentUser = api.getCurrentUser();
    api.modifyClass("controller:preferences/notifications", Superclass => class extends Superclass {
      save() {
        this.get("saveAttrNames").push("custom_fields");
        super.save();
      }
      static #_ = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    });
    if (currentUser?.custom_fields?.disable_retorts) {
      return;
    }
    api.includePostAttributes("can_retort", "my_retorts", "retorts");
    api.decorateWidget("post-contents:after-cooked", helper => {
      const post = helper.getModel();
      return helper.attach("post-retort-container", {
        post
      });
    });
    api.addPostClassesCallback(attrs => {
      const Retort = (0, _getOwner.getOwnerWithFallback)(api).lookup("service:retort");
      if (!Retort.disableShowForTopic(attrs.topic)) {
        return ["retort"];
      }
    });
    api.modifyClass("controller:topic", Superclass => class extends Superclass {
      onRetortUpdate(data) {
        const {
          id,
          retorts
        } = data;
        const post = this.get("model.postStream.posts").findBy("id", id);
        if (post) {
          post.setProperties({
            retorts
          });
          this.appEvents.trigger("post-stream:refresh", {
            id: post.get("id")
          });
        }
      }
      subscribe() {
        super.subscribe();
        this.messageBus.subscribe(`/retort/topics/${this.get("model.id")}`, this.onRetortUpdate.bind(this));
      }
      unsubscribe() {
        super.unsubscribe();
        this.messageBus.unsubscribe("/retort/topics/*");
      }
    });
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          firstButtonKey
        }
      } = _ref;
      dag.add("retort", _retortButton.default, {
        before: firstButtonKey
      });
    });
  }
  var _default = _exports.default = {
    name: "retort",
    initialize: function (container) {
      if (!container.lookup("service:site-settings").retort_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.3", api => initializePlugin(api));
    }
  };
});